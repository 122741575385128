<template>
  <div
    v-if="listing"
    class="card--booking card--my-listing"
    :class="{ 'premier--listing': listing.listingType === 'Premier' }"
  >
    <router-link
      :to="listing.previewLink ? listing.previewLink : { path: '' }"
      class="top--my-listing"
      style="text-decoration: none"
    >
      <div v-show="listing.listingType === 'Premier'" class="indicator premium">
        Premium Listing
      </div>
      <div class="left--part">
        <div v-if="listing.imgSource">
          <picture>
            <source :srcset="listing.imgSource" type="image/webp" />
            <source :srcset="getFallbackImage(listing.imgSource)" type="image/jpeg" />
            <img :src="getFallbackImage(listing.imgSource)" />
          </picture>
        </div>
      </div>
      <div class="right--part">
        <div class="top--part">
          <div class="title--text" :class="{ 'multiline-ellipsis-2': $mq === 'xs' }">
            {{ listing.listing_title }}
          </div>
        </div>
        <div class="bottom--part">
          <div class="left--col">
            <div class="label--container">
              <div class="label">
                {{ $t(`general.filter.${listing.type}`) }}
              </div>
              <div class="label">{{ listing.propertyType }}</div>
            </div>
            <!--            <div class="location">-->
            <!--              <img src="@/assets/img/icons/pin-1.svg" alt="Location Pin" />-->
            <!--              <span>{{ listing.property.location.city_name }}</span>-->
            <!--            </div>-->
          </div>
          <div class="right--col" v-if="listing.type === 'R' && listing.rentPrices">
            <div v-for="(rentPrice, key) in listing.rentPrices" :key="key">
              <div
                class="price--before"
                v-if="listing.discount_price != null || listing.discount_percentage != null"
              >
                {{ $n(rentPrice.price_raw, 'currency', 'id-ID') }}
              </div>
              <div class="price--after">
                {{
                  $n(
                    getPriceAfterDiscount(
                      listing.discount_percentage,
                      listing.discount_price,
                      rentPrice.price_raw,
                    ).price_after_discount,
                    'currency',
                    'id-ID',
                  )
                }}
                / {{ rentPrice.period | lowerCase }}
              </div>
            </div>
            <p
              style="text-align: right"
              class="impression--text text-muted"
              v-if="metaPagination && !isNaN(metaPagination.maxImpression)"
            >
              {{ $t('myListing.impressionsLeft') }}
              <span
                ><strong>{{ listing.impression }}</strong></span
              >
              {{ $t('myListing.impressionsRight') }}
            </p>
          </div>
          <div class="right--col" v-else-if="listing.type === 'S' && listing.sell_price_raw">
            <div
              class="price--before"
              v-if="listing.discount_price != null || listing.discount_percentage != null"
            >
              {{ $n(listing.sell_price_raw, 'currency', 'id-ID') }}
            </div>
            <div class="price--after">
              {{
                $n(
                  getPriceAfterDiscount(
                    listing.discount_percentage,
                    listing.discount_price,
                    listing.sell_price_raw,
                  ).price_after_discount,
                  'currency',
                  'id-ID',
                )
              }}
            </div>
            <p
              style="text-align: right"
              class="impression--text text-muted"
              v-if="metaPagination && !isNaN(metaPagination.maxImpression)"
            >
              {{ $t('myListing.impressionsLeft') }}
              <span
                ><strong>{{ listing.impression }}</strong></span
              >
              {{ $t('myListing.impressionsRight') }}
            </p>
          </div>
        </div>
      </div>
    </router-link>
    <div class="bottom--my-listing">
      <activation-toggle
        v-if="activeCategory === 'notActive' || activeCategory === 'active'"
        :activated="activeCategory === 'active'"
        :listing-uuid="listing.uuid"
        :page="page + ''"
      />
      <publish-toggle
        v-if="activeCategory === 'draft'"
        :listing-uuid="listing.uuid"
        :page="page + ''"
      />
      <edit-btn :listing-uuid="listing.uuid" />
      <duplicate-btn
        v-if="activeCategory === 'active' || activeCategory === 'notActive'"
        :listing-uuid="listing.uuid"
      />
      <delete-btn
        :listing-uuid="listing.uuid"
        :page="page + ''"
        v-if="activeCategory !== 'active'"
      />
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import ActivationToggle from '@/components/mylisting/buttons/activation-toggle';
import PublishToggle from '@/components/mylisting/buttons/publish-toggle';
import EditBtn from '@/components/mylisting/buttons/edit-btn';
import DuplicateBtn from '@/components/mylisting/buttons/duplicate-btn.vue';
import DeleteBtn from '@/components/mylisting/buttons/delete-btn.vue';

import { mapGetters, mapState } from 'vuex';
export default {
  mixins: [HelperMixin],
  components: { ActivationToggle, EditBtn, DuplicateBtn, DeleteBtn, PublishToggle },
  props: {
    listing: {
      default: null,
      require: true,
    },
    premier: {
      default: false,
    },
    page: {},
  },
  computed: {
    ...mapState({
      shortlists: (state) => state.v2.search.shortlists,
      metaPagination: (state) => state.myListing.metaPagination,
      loggedIn: (state) => state.global.loggedIn,
    }),
    ...mapGetters({
      activeCategory: 'myListing/activeCategory',
    }),
    shortlisted() {
      if (this.shortlists && this.shortlists.length === 0) {
        return false;
      } else {
        return this.shortlists.indexOf(this.listing.id) !== -1;
      }
    },
  },
  methods: {
    async toggleShortlist() {
      if (this.loggedIn) {
        let response = await this.$store.dispatch(
          'v2/listing/addOrRemoveShortlist',
          this.listing.id,
        );
        await this.$store.commit('v2/search/set_shortlists', response);
      } else {
        this.$modal.show('modal-login');
      }
    },
  },
  filters: {
    lowerCase(e) {
      return e.toLowerCase();
    },
  },
};
</script>
