<template>
  <section class="my--listing-header">
    <div class="category--container" v-if="$mq !== 'xs'">
      <router-link class="btn btn-primary" to="/dashboard/mylisting">
        {{ $t('dashboard.myListing') }}
      </router-link>
      <router-link class="btn btn-primary--outline" to="/dashboard/mybooking">
        {{ $t('dashboard.myBooking') }}
      </router-link>
      <router-link class="btn btn-primary--outline" to="/dashboard/favorites">
        {{ $t('dashboard.favorite') }}
      </router-link>
    </div>
    <div class="dashboard--search-container mb-0">
      <!--      <div class="dropdown&#45;&#45;wrapper">-->
      <!--        <v-select-->
      <!--          :value="activeCategoryObject"-->
      <!--          @input="inputCategoryHandler"-->
      <!--          :hint="-->
      <!--            `${$t('general.filter.category')} ${$t(`general.filter.${activeCategoryObject.name}`)}`-->
      <!--          "-->
      <!--          :items="categories"-->
      <!--          :item-text="categoryLabel"-->
      <!--          item-value="name"-->
      <!--          class="basic&#45;&#45;dropdown"-->
      <!--          persistent-hint-->
      <!--          return-object-->
      <!--          single-line-->
      <!--        />-->
      <!--      </div>-->
      <div class="dropdown--wrapper">
        <v-select
          :value="activeSortBy"
          @input="inputSortByHandler"
          :hint="`${$t('general.filter.sort')} ${$t(`general.filter.${activeSortBy.name}`)}`"
          :items="sortBys"
          :item-text="sortByLabel"
          item-value="name"
          class="basic--dropdown"
          persistent-hint
          return-object
          single-line
        />
      </div>
      <!--      <div class="dropdown&#45;&#45;wrapper">-->
      <!--        <v-select-->
      <!--          :value="listingType"-->
      <!--          @input="inputListingTypeHandler"-->
      <!--          :items="listingTypes"-->
      <!--          :hint="`${$t('addListing.listingType')} ${$t(`general.filter.${listingType}`)}`"-->
      <!--          class="basic&#45;&#45;dropdown"-->
      <!--          persistent-hint-->
      <!--          single-line-->
      <!--        >-->
      <!--          <template slot="selection" slot-scope="{ item }">-->
      <!--            {{ $t(`general.filter.${item}`) }}-->
      <!--          </template>-->
      <!--          <template slot="item" slot-scope="{ item }">-->
      <!--            {{ $t(`general.filter.${item}`) }}-->
      <!--          </template>-->
      <!--        </v-select>-->
      <!--      </div>-->
      <!--      <div class="search&#45;&#45;button">-->
      <!--        <img src="@/assets/img/icons/search.svg" alt="Cari" />-->
      <!--      </div>-->
    </div>
    <div class="dashboard--search-container">
      <search-listing />
      <router-link class="hide--in-mobile" to="/listing/add">
        <div class="btn btn-primary--outline">+ {{ $t('general.btn.addListing') }}</div>
      </router-link>
    </div>
  </section>
</template>

<script>
import SearchListing from '@/components/dashboard/my-listing/search-listing';
import { mapState, mapGetters } from 'vuex';

export default {
  components: { SearchListing },
  computed: {
    ...mapState({
      sortBys: (state) => state.myListing.sortByNavList,
      listingType: (state) => state.myListing.listingType,
      listingTypes: (state) => state.global.listingTypes,
    }),
    ...mapGetters({
      categories: 'myListing/allowedCategory',
      activeSortBy: 'myListing/activeSortBy',
      activeCategoryObject: 'myListing/activeCategoryObject',
    }),
  },
  methods: {
    sortByLabel(value) {
      return this.$i18n.t(`general.filter.${value.name}`);
    },
    categoryLabel(value) {
      return this.$i18n.t(`general.filter.${value.name}`);
    },
    inputCategoryHandler(value) {
      let query = Object.assign({}, this.$route.query);
      delete query.page;
      this.$router.replace({ query });
      this.$store.dispatch('myListing/changeActiveCategory', value.name);
    },
    inputSortByHandler(value) {
      this.$store.dispatch('myListing/changeActiveSortBy', value.name);
    },
    inputListingTypeHandler(value) {
      this.$store.commit('myListing/SET_LISTING_TYPE', value);
      this.$store.dispatch('myListing/changeListingType');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
.dashboard--search-container {
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  .search--listing-wrapper {
    width: initial;
    min-width: 260px;
    @media #{$tablets} {
      min-width: 200px;
    }
    @media #{$phones} {
      min-width: calc(80% - 20px);
    }
  }
  a {
    font-family: Poppins-SemiBold;
  }
}
</style>
