<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <dashboard-skeleton :page-loading="myListingLoading">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        <div class="d-flex justify-space-between align-center" style="width: 100%">
          <div>
            {{ $t('dashboard.myListing') }}
          </div>
          <router-link to="/listing/add"> + {{ $t('general.btn.addListing') }} </router-link>
        </div>
      </div>
      <div class="skeleton--container">
        <listings-header />
        <div
          class="mb-2"
          v-if="myListingMeta && !isNaN(myListingMeta.maxImpression)"
          style="text-align: right"
        >
          {{ myListingMeta.currentImpression }} /
          {{
            myListingMeta.maxImpression > 0
              ? myListingMeta.maxImpression
              : $t('myListing.unlimited')
          }}
          |
          <span class="text-muted" style="font-family: Poppins-Bold">
            {{ $t('myListing.usedImpression') }}
          </span>
        </div>
        <v-tabs center-active v-if="categories && categories.length > 0">
          <v-tab
            :href="`#tab-${index}`"
            v-for="(category, index) in categories"
            :key="`category-title-${index}`"
            @click="changeCategory(category)"
          >
            {{ $t(`general.filter.${category.name}`) }}
          </v-tab>
          <v-tab-item
            :value="`tab-${index}`"
            v-for="(category, index) in categories"
            :key="`category-content-${index}`"
          >
            <listings />
            <pagination
              :push-state="false"
              :meta="myListingMeta"
              @changePage="getListings"
              :maxVisibleButtons="5"
            />
          </v-tab-item>
        </v-tabs>
      </div>
    </dashboard-skeleton>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DashboardSkeleton from '@/components/dashboard/dashboard-skeleton';
import ListingsHeader from '@/components/dashboard/my-listing/listings-header';
import Listings from '@/components/dashboard/my-listing/listings';
import DefaultHeadMixin from '@/mixins/defaultHead';
import { mapGetters, mapState } from 'vuex';

const Pagination = () => import('@/components/utils/fractal-pagination.vue');
export default {
  mixins: [DefaultHeadMixin],
  components: {
    Pagination,
    DefaultLayout,
    ListingsHeader,
    Listings,
    DashboardSkeleton,
  },
  data() {
    return {
      checkBankAccountDone: false,
    };
  },
  computed: {
    ...mapState({
      myListingLoading: (state) => state.myListing.isFetchingListing,
      myListingMeta: (state) => state.myListing.metaPagination,
    }),
    ...mapGetters({
      categories: 'myListing/allowedCategory',
      myListingActiveSortBy: 'myListing/activeSortBy',
      myListingCategories: 'myListing/allowedCategory',
    }),
    breadcrumbs() {
      const currentName = this.$t('dashboard.myListing');
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: currentName,
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {
    getListings(page) {
      this.$store.dispatch('myListing/getListings', page ? page : 1);
    },
    close() {
      this.$store.commit('myListing/SET_ACTIVE_SIDEBAR', false);
    },
    async changeCategory(category) {
      await this.$store.dispatch('myListing/changeActiveCategory', category.name);
    },
  },
  async mounted() {
    await this.$store.commit('myListing/RESTORE_INITIAL_STATE');
    this.$store.commit('myListing/SET_ACTIVE_SIDEBAR', true);
    await this.$store.dispatch('myListing/getCategoryCountData');
    this.getListings(this.$route.query.page);
  },
};
</script>
